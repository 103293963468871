import React, { useState } from 'react';
import { Box, Drawer, Breadcrumbs, Link, Typography, useMediaQuery } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home'; 
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Sidebar from './common_components/SideBar';
import AppNavBar from './common_components/AppNavBar';
import Footer from './common_components/Footer';
import List from './Inventory/List';
import productList from './productList.json';

const drawerWidth = 220;

const MainLayout = () => {
  const [selectedCategory, setSelectedCategory] = useState('All Products');
  const [mobileOpen, setMobileOpen] = useState(false); 
  const [searchQuery, setSearchQuery] = useState('');
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const isMobile = useMediaQuery('(max-width:600px)');

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    if (isMobile) {
      setMobileOpen(false);
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  return (
    <Box sx={{ display: 'flex' }}>

      <AppNavBar onMenuClick={handleDrawerToggle} onSearch={handleSearch} />

      <Drawer
        variant={isMobile ? 'temporary' : 'persistent'}
        open={isMobile ? mobileOpen : isSidebarOpen}
        onClose={handleDrawerToggle}
        sx={{
          display: { xs: 'block', sm: 'block' },
          width: isSidebarOpen ? drawerWidth : 60,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: isSidebarOpen ? drawerWidth : 60,
            boxSizing: 'border-box',
            marginTop: isMobile ? '70px' : '64px',
            border: 'none'
          },
        }}
      >
        <Sidebar 
          onClose={toggleSidebar} 
          onCategoryClick={handleCategoryClick} 
          selectedCategory={selectedCategory}
        />
      </Drawer>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          padding: { xs: 2, sm: 3 },
          marginTop: 8,
          transition: 'margin-left 0.4s ease',
          backgroundColor: '#fff',
          minHeight: '100vh',
        }}
      >
        {/* Breadcrumb */}
        {isMobile && <Breadcrumbs
          aria-label="breadcrumb"
          separator={<NavigateNextIcon fontSize="small" />}
          sx={{
            marginBottom: 2,
            display: 'flex',
            alignItems: 'center',
            fontSize: '12px',
            color: '#00796b',
            fontFamily: 'monospace',
            '& .MuiBreadcrumbs-separator': { color: '#00796b' },
          }}
        >
          {/* Breadcrumb for All Products */}
          <Link
            underline="hover"
            color={selectedCategory === 'All Products' ? 'text.primary' : 'inherit'}
            onClick={() => handleCategoryClick('All Products')}
            sx={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              fontWeight: selectedCategory === 'All Products' ? 'bold' : 'normal',
              color: selectedCategory === 'All Products' ? '#00796b' : 'inherit',
              '&:hover': { color: '#004d40' },
            }}
          >
            <HomeIcon sx={{ marginRight: 0.5 }} fontSize="small" />
            All Products
          </Link>

          {/* Show current category if it's not All Products */}
          {selectedCategory !== 'All Products' && (
            <Typography
              color="text.primary"
              sx={{ fontWeight: 'bold', fontFamily: 'monospace', color: '#00796b' }}
            >
              {selectedCategory.name}
            </Typography>
          )}
        </Breadcrumbs>}

        <List productList={productList} searchQuery={searchQuery} selectedCategory={selectedCategory} />
      </Box>
    </Box>
    
  );
};

export default MainLayout;
