import React, { useState } from 'react';
import { Box, InputBase, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const SearchBar = ({ onSearch }) => {
  const [query, setQuery] = useState('');

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    onSearch(value);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: 'rgba(255, 255, 255, 0.15)',
          borderRadius: '25px',
          width: { xs: '85%', md: '50%', lg: '60%' },
          padding: '5px 20px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        }}
      >
        <IconButton>
          <SearchIcon sx={{ color: '#fff', marginRight: '10px' }} />
        </IconButton>
        <InputBase
          placeholder="Search products"
          value={query}
          onChange={handleSearchChange}
          sx={{
            color: '#fff',
            width: '100%',
            fontFamily: 'monospace',
            fontSize: { xs: '14px', md: '16px' },
          }}
        />
      </Box>
    </Box>
  );
};

export default SearchBar;
