import React, { useState } from 'react';
import { Box, Typography, IconButton, Button, TextField, Card, CardContent, Tooltip, Autocomplete } from '@mui/material';
import { AddCircleOutline as AddIcon, RemoveCircleOutline as RemoveIcon, Delete as DeleteIcon, ArrowBack } from '@mui/icons-material';
import AppNavBar from '../common_components/AppNavBar';
import { useSelector, useDispatch } from 'react-redux';
import { addToCart, removeFromCart, deleteItemFromCart, clearCart } from '../redux/cartSlice';
import { useNavigate } from 'react-router-dom';
import emailjs from 'emailjs-com';

const ReviewOrderPage = () => {
  const cartItems = useSelector((state) => state.cart.cartItems);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const restaurantList = [
    { id: 1, name: 'A2B - Adyar Ananda Bhavan' },
    { id: 2, name: 'Spicerack - Plano' },
    { id: 3, name: 'Mana Adda' },
    { id: 4, name: 'Chalo India' },
    { id: 5, name: 'Suravi Chapter 1' },
    { id: 6, name: 'Suravi Chapter 2' },
    { id: 7, name: 'Goli Soda' },
    { id: 8, name: 'Desi Bites Plano' },
    { id: 9, name: 'Hashtag India - McKinney' },
    { id: 10, name: 'Hashtag India - Aubrey' },
    { id: 11, name: 'Marina' },
    { id: 12, name: 'Spice Junction' },
    { id: 13, name: 'Kuppanna - Little Elm' },
    { id: 14, name: 'Kuppanna - Plano' },
    { id: 15, name: 'Swadeshi' },
    { id: 16, name: 'Chowrastha - Frisco' },
    { id: 17, name: 'Sai Gayatri' },
    { id: 18, name: 'Dwaraka' },
  ];

  const [restaurantName, setRestaurantName] = useState('');
  const [selectedRestaurantId, setSelectedRestaurantId] = useState('');
  const [restaurantEmail, setRestaurantEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleNameChange = (event, newValue) => {
    if (newValue) {
      setRestaurantName(newValue.name);
      setSelectedRestaurantId(newValue.id);
      console.log('Selected Restaurant:', newValue);
    } else {
      setRestaurantName('');
      setSelectedRestaurantId('');
    }
    setIsDropdownOpen(false);
  };

  const handleEmailChange = (event) => {
    const email = event.target.value;
    setRestaurantEmail(email);
    setIsEmailValid(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email));
  };

  const handleInputChange = (event, value) => {
    setInputValue(value);
    setIsDropdownOpen(value.length >= 3);
  };

  const handleAddToCart = (item) => {
    dispatch(addToCart(item));
  };

  const handleRemoveFromCart = (item) => {
    dispatch(removeFromCart(item.id));
  };

  const handleDeleteItem = (item) => {
    dispatch(deleteItemFromCart(item.id));
  };

  const handleClearCart = () => {
    dispatch(clearCart());
  };

  const handleSubmitOrder = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    const deliveryDate = tomorrow.toLocaleDateString();

    const orderDetails = {
      restaurantName,
      selectedRestaurantId,
      restaurantEmail,
      items: Object.values(cartItems).map(item => ({
        name: item.name,
        quantity: item.quantity,
      })),
      deliveryDate: deliveryDate,
    };
    console.log('details:', orderDetails)

    const templateParams = {
      restaurant_name: restaurantName,
      restaurant_id: selectedRestaurantId,
      items: orderDetails.items.map(item => `${item.name} : ${item.quantity}`).join(', '),
      delivery_date: deliveryDate,
      to_email: restaurantEmail,   
    };

    emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      templateParams,
      process.env.REACT_APP_EMAILJS_USER_ID)

    handleClearCart();
    navigate('/orderconfirmation', { state: { orderDetails } });
  };
  
  return (
    <>
      <AppNavBar showSearchBar={false} />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mt: { xs: '80px', md: '80px' },
          padding: { xs: '0 16px', md: '0 24px' },
        }}
      >
        <IconButton onClick={() => navigate('/')} sx={{ color: '#00796b' }}>
          <ArrowBack />
        </IconButton>
        <Typography
          gutterBottom
          align="left"
          sx={{
            fontWeight: 'bold',
            color: '#00796b',
            backgroundColor: '#fff',
            zIndex: 1,
            padding: '10px 0',
            fontFamily: 'monospace',
            marginLeft: '10px',
          }}
        >
          Review your order
        </Typography>
      </Box>
      {Object.keys(cartItems).length === 0 ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
            textAlign: 'center',
          }}
        >
          {/* Icon or GIF */}
          <Box
            component="img"
            src="https://cdn-icons-png.flaticon.com/512/2038/2038854.png"
            alt="Empty Cart"
            sx={{ width: '150px', height: '150px', marginBottom: '20px' }}
          />
          <Typography sx={{ fontFamily: 'monospace', color: '#00796b' }}>
            This empty cart would look a lot better with some items in it.
          </Typography>
        </Box>
      ) :
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'space-between',
            gap: { xs: 2, md: 4 },
            p: 2,
          }}
        >
          {/* Left Column - Scrollable Cart Items */}
          <Box
            sx={{
              flex: 1,
              pb: 2,
            }}
          >
            {Object.keys(cartItems).map((itemId) => {
              const item = cartItems[itemId];
              return (
                <Card
                  key={itemId}
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    alignItems: 'center',
                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.3)',
                    borderRadius: '10px',
                    transition: 'transform 0.2s ease-in-out',
                    '&:hover': { transform: 'scale(1.01)' },
                    width: '100%',
                    mb: 2,
                  }}
                >
                  <Box
                    component="img"
                    src={item.image}
                    alt={item.name}
                    sx={{
                      width: { xs: '60px', sm: '80px' }, // Smaller on mobile
                      height: 'auto',
                      borderRadius: '8px',
                      objectFit: 'cover',
                      mb: { xs: 1, sm: 0 },
                      mx: 'auto', // Center image on mobile
                      transition: 'transform 0.3s ease-in-out', // Add transition for zoom
                      '&:hover': {
                        transform: 'scale(1.05)', // Slight zoom on hover
                      },
                    }}
                  />
                  <CardContent sx={{ flex: '1', textAlign: { xs: 'center', sm: 'left' }, px: 1 }}>
                    <Typography sx={{ fontFamily: 'monospace', fontSize: '14px', fontWeight: 'bold' }}>
                      {item.name}
                    </Typography>
                    <Typography
                      sx={{
                        color: item.stock > 0 ? 'green' : 'red',
                        fontWeight: 'bold',
                        fontSize: '12px',
                        mb: 0.5,
                      }}
                    >
                      {item.stock > 0 ? `${item.stock} in stock` : 'Out of Stock'}
                    </Typography>
                    <Typography sx={{ fontSize: '12px', color: '#555' }}>
                      Quantity: {item.quantity}
                    </Typography>
                  </CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      gap: 0.5,
                      mt: { xs: 1, sm: 0 },
                      '& > *': {
                        transition: 'color 0.2s ease-in-out',
                        fontSize: 'small',
                      },
                    }}
                  >
                    <Tooltip title="Add">
                      <IconButton
                        onClick={() => handleAddToCart(item)}
                        disabled={cartItems[itemId]?.quantity >= [itemId].stock}
                        sx={{ color: 'gray', '&:hover': { color: 'green' } }}
                      >
                        <AddIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Remove">
                      <IconButton
                        onClick={() => handleRemoveFromCart(item)}
                        sx={{ color: 'gray', '&:hover': { color: 'orange' } }}
                      >
                        <RemoveIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton
                        onClick={() => handleDeleteItem(item)}
                        sx={{ color: 'gray', '&:hover': { color: 'red' } }}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Card>
              );
            })}
          </Box>

          {/* Right Column - Sticky Restaurant Details & Buttons */}
          <Box
            sx={{
              flex: 1,
              position: { md: 'sticky' },
              top: 100, // Adjust to desired distance from the top
              alignSelf: 'start',
              pb: { xs: 2, md: 0 },
            }}
          >
            <Autocomplete
              value={restaurantList.find((option) => option.name === restaurantName) || null}
              options={inputValue.length >= 3 ? restaurantList : []}
              getOptionLabel={(option) => option.name}
              onChange={handleNameChange}
              onInputChange={handleInputChange}
              inputValue={inputValue}
              open={isDropdownOpen} 
              freeSolo
              onClose={() => setIsDropdownOpen(false)}
              sx={{
                '& .MuiInputBase-root': {
                  fontFamily:'monospace',
                  fontSize: '12px',
                  borderRadius: '10px',
                  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.3)',
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Restaurant Name"
                  placeholder="Enter your restaurant name"
                  variant="outlined"
                  fullWidth
                  sx={{ fontFamily: 'monospace', mb: 2}}
                />
              )}
            />
            <TextField
              label="Restaurant Email"
              placeholder="Enter your restaurant email"
              fullWidth
              value={restaurantEmail}
              onChange={handleEmailChange}
              error={!isEmailValid && restaurantEmail.length > 0}
              helperText={!isEmailValid && restaurantEmail.length > 0 ? 'Enter a valid email' : ''}
              sx={{ fontFamily: 'monospace', mb: 2,
                '& .MuiInputBase-root': {
                  fontFamily:'monospace',
                  fontSize: '12px',
                  borderRadius: '10px'
                },
              }}
            />
            <Button
              disabled={Object.keys(cartItems).length === 0}
              onClick={handleClearCart}
              sx={{
                background: 'linear-gradient(90deg, #a5eb98)',
                color: '#00796b',
                fontFamily: 'monospace',
                borderRadius: '10px',
                fontSize: '12px',
                transition: 'background-color 0.4s ease, transform 0.3s ease',
                padding: '10px 20px',
                '&:hover': {
                  background: 'linear-gradient(to right, #43cea2, #00796b)',
                  transform: 'scale(1.03)',
                  color: '#ffffff',
                },
              }}
            >
              Clear All
            </Button>
            <Button
              onClick={handleSubmitOrder}
              disabled={!isEmailValid || Object.keys(cartItems).length === 0}
              sx={{
                background: 'linear-gradient(90deg, #a5eb98)',
                color: '#00796b',
                fontFamily: 'monospace',
                borderRadius: '10px',
                fontSize: '12px',
                padding: '10px 20px',
                transition: 'background-color 0.4s ease, transform 0.3s ease',
                '&:hover': {
                  background: 'linear-gradient(to right, #43cea2, #00796b)',
                  transform: 'scale(1.03)',
                  color: '#ffffff',
                },
              }}
            >
              Submit Order
            </Button>
          </Box>
        </Box>}
    </>
  );
};

export default ReviewOrderPage;