import React, { useContext } from 'react';
import { AppBar, Toolbar, IconButton, Badge, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CartIcon from '@mui/icons-material/ShoppingCart';
import SearchBar from './SearchBar';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AppNavBar = ({ onMenuClick, onSearch, showSearchBar=true, showShopingCart=true }) => {
  const cartCount = useSelector((state) => state.cart.cartCount);
  const location = useLocation();
  const hideMenuIcon = ['/cart', '/orderconfirmation'].includes(location.pathname);

  return (
    <AppBar
      position="fixed"
      sx={{
        background: 'linear-gradient(45deg, #00796b, #4db6ac)',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: { xs: '10px', md: '0 20px' },
        }}
      >
        <IconButton sx={{ display: hideMenuIcon ? 'none' : { md: 'none' } }} edge="start" color="inherit" onClick={onMenuClick}>
          <MenuIcon />
        </IconButton>

        <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
        {showSearchBar && <SearchBar onSearch={onSearch} />}
        </Box>

        {showShopingCart && <IconButton color="inherit" component={Link} to="/cart">
          <Badge badgeContent={cartCount} color="error">
            <CartIcon />
          </Badge>
        </IconButton>}
      </Toolbar>
    </AppBar>
  );
};

export default AppNavBar;
