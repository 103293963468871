import React, { useState, useEffect } from "react";
import { Box, Typography, Card, CardContent, CardActions, IconButton, Link, Collapse, Dialog, DialogContent, Fab } from '@mui/material';
import { AddCircleOutlineOutlined as AddIcon, RemoveCircleOutlineOutlined as RemoveIcon, ExpandMore as ExpandMoreIcon, ArrowUpward as ArrowUpIcon } from '@mui/icons-material';
import { useDispatch, useSelector } from "react-redux";
import { addToCart, removeFromCart } from "../redux/cartSlice";

const List = ({ productList, searchQuery, selectedCategory }) => {

    const [expandedId, setExpandedId] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [showScrollTop, setShowScrollTop] = useState(false);

    const dispatch = useDispatch();
    const cartItems = useSelector((state) => state.cart.cartItems);

    useEffect(() => {
        const handleScroll = () => {
            setShowScrollTop(window.scrollY > 300);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    let itemsToFilter = selectedCategory === 'All Products'
        ? productList.flatMap(category => category.items)
        : selectedCategory?.items || [];

    const handleAddToCart = (item) => {
        dispatch(addToCart(item));
    };

    const handleRemoveFromCart = (item) => {
        dispatch(removeFromCart(item.id));
    };

    const filteredItems = itemsToFilter.filter((item) =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleExpandClick = (id) => {
        setExpandedId(prevId => (prevId === id ? null : id));
    };

    const handleImageClick = (image) => {
        setSelectedImage(image);
    };

    const handleCloseImageDialog = () => {
        setSelectedImage(null);
    };

    return (
        <Box component="main" sx={{ flexGrow: 1, padding: { xs: 2, sm: 3 }, backgroundColor: '#fff', minHeight: '100vh' }}>
            {filteredItems.length > 0 ? (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '14px', padding: '8px' }}>
                    {filteredItems.map((item) => {
                        const stock = cartItems[item.id]?.stock ?? item.stock;

                        return (
                            <Box key={item.id} sx={{ flex: '1 1 calc(100% - 16px)', maxWidth: 'calc(100% - 16px)', '@media (min-width: 600px)': { flex: '1 1 calc(50% - 16px)', maxWidth: 'calc(50% - 16px)' }, '@media (min-width: 900px)': { flex: '1 1 calc(33.333% - 16px)', maxWidth: 'calc(33.333% - 16px)' }, display: 'flex' }}>
                                <Card
                                    sx={{
                                        position: 'relative',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.1)',
                                        transition: 'transform 0.3s ease-in-out',
                                        borderRadius: '20px',
                                        '&:hover': { transform: 'scale(1.05)' },
                                        height: '100%',
                                        maxWidth: { xs: '100%', sm: '360px', md: '280px' },
                                        width: { xs: '100%', sm: 'auto', md: '100%' },
                                    }}
                                >
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '0px',
                                            right: '0px',
                                            background: stock > 10
                                                ? 'linear-gradient(45deg, #66bb6a, #43a047)'
                                                : stock > 0
                                                    ? 'linear-gradient(45deg, #ffa726, #fb8c00)'
                                                    : 'linear-gradient(45deg, #ef5350, #e53935)',
                                            color: '#fff',
                                            padding: '6px 15px',
                                            borderRadius: '0 15px 0 15px',
                                            fontSize: { xs: '10px', sm: '12px' },
                                            fontWeight: '500',
                                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
                                            zIndex: 1,
                                            textAlign: 'center',
                                            letterSpacing: '0.5px',
                                        }}
                                    >
                                        {stock > 0 ? `${stock} in stock` : 'Out of Stock'}
                                    </Box>

                                    <CardContent sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'center', padding: '8px' }}>
                                        <Box component="img" src={item.image} onClick={() => handleImageClick(item.image)} sx={{ width: { xs: '70px', sm: '90px', md: '60px' }, height: 'auto', borderRadius: '12px', marginRight: { sm: '5px' }, marginBottom: { xs: '10px', sm: 0 }, transition: 'transform 0.3s ease-in-out', '&:hover': { transform: { md: 'scale(1.2)' } }, cursor: { xs: 'pointer', md: 'zoom-in' } }} />
                                        <Box sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
                                            <Typography sx={{ fontSize: { xs: '12px', sm: '12px' }, fontWeight: 'bold', fontFamily: 'monospace', whiteSpace: 'nowrap' }}>{item.name}</Typography>
                                        </Box>
                                    </CardContent>

                                    <Typography sx={{ fontFamily: 'monospace', fontSize: { xs: '12px', sm: '14px' }, whiteSpace: 'nowrap', marginLeft: '8px', textAlign: 'center', letterSpacing: '-0.5px', mb: 1 }}>
                                        {cartItems[item.id]?.quantity === 1
                                            ? `1 item added to cart`
                                            : `${cartItems[item.id]?.quantity || 0} items added to cart`}
                                    </Typography>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px' }}>
                                        <IconButton sx={{ color: 'gray', '&:hover': { color: 'green', transform: 'scale(1.1)' } }} onClick={() => handleAddToCart(item)} disabled={cartItems[item.id]?.quantity >= item.stock}>
                                            <AddIcon />
                                        </IconButton>
                                        <IconButton sx={{ color: 'gray', '&:hover': { color: 'red', transform: 'scale(1.1)' } }} onClick={() => handleRemoveFromCart(item)}>
                                            <RemoveIcon />
                                        </IconButton>
                                    </Box>

                                    <CardActions sx={{ justifyContent: 'center', padding: '10px', textAlign: 'center', flexDirection: 'column' }}>
                                        {(item.pieces || item.features || item.material) && (
                                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                <Link onClick={() => handleExpandClick(item.id)} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#185a9d', textTransform: 'none', fontSize: '12px', fontFamily: 'monospace', cursor: 'pointer', textDecoration: 'none', '&:hover': { textDecoration: 'none' } }}>
                                                    {expandedId === item.id ? 'Less' : 'More'}
                                                    <ExpandMoreIcon sx={{ marginLeft: '4px', transform: expandedId === item.id ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s ease-in-out' }} />
                                                </Link>
                                            </Box>
                                        )}
                                        <Collapse in={expandedId === item.id} timeout="auto" unmountOnExit>
                                            <Box sx={{ padding: '10px', display: 'flex', flexDirection: 'column', gap: '5px', alignItems: 'flex-start', width: '100%', textAlign: 'left' }}>
                                                {item.pieces && <Typography sx={{ fontSize: '12px', fontFamily: 'monospace', marginBottom: '4px', color: 'gray' }}>Quantity: {item.pieces || 'N/A'}</Typography>}
                                                {item.features && <Typography sx={{ fontSize: '12px', fontFamily: 'monospace', marginBottom: '4px', color: 'gray' }}>Features: {item.features || 'N/A'}</Typography>}
                                                {item.material && <Typography sx={{ fontSize: '12px', fontFamily: 'monospace', color: 'gray' }}>Material: {item.material || 'N/A'}</Typography>}
                                            </Box>
                                        </Collapse>
                                    </CardActions>
                                </Card>
                            </Box>
                        );
                    })}
                </Box>
            ) : (
                <Typography sx={{ fontFamily: 'monospace', textAlign: 'center' }}>
                    No items found for <strong>{searchQuery}</strong>. Please search for the available items from the list.
                </Typography>
            )}

            <Dialog open={!!selectedImage} onClose={handleCloseImageDialog} maxWidth="md">
                <DialogContent>
                    <Box component="img" src={selectedImage} sx={{ width: '100%', height: 'auto', borderRadius: '12px' }} />
                </DialogContent>
            </Dialog>
            {showScrollTop && (
                <Fab
                    color="primary"
                    onClick={scrollToTop}
                    sx={{
                        position: 'fixed',
                        bottom: { xs: '16px', md: '32px' },
                        right: { xs: '8px', md: '32px' },
                        width: { xs: '40px', md: '56px' },
                        height: { xs: '40px', md: '56px' },
                        backgroundColor: '#00796b',
                        '&:hover': {
                            backgroundColor: '#004d40',
                        },
                    }}
                >
                    <ArrowUpIcon />
                </Fab>)}
        </Box>
    );
};

export default List;
