import React from 'react';
import { Box, Grid, Card, CardActionArea, CardMedia, CardContent, Typography, useMediaQuery } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/images/grubpack.png';

const cities = [
  { name: 'Austin', image: '/images/products/austin.png', key: 'austin' },
  { name: 'Dallas', image: '/images/products/dallas.png', key: 'dallas' },
  { name: 'San Antonio', image: '/images/products/sanantonio.png', key: 'sanantonio' },
];

const ChooseStore = ({ onSelectCity }) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)');

  const handleCityClick = (cityKey) => {
    onSelectCity(cityKey);   
    navigate('/list');       
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 3 }}>
      {/* Logo */}
      <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: isMobile ? 'center' : 'flex-start', 
          width: '100%', 
          marginBottom: '20px', 
        }}
      >
        <img src={logo} alt="Grub Pack Logo" style={{ width: '95px', height: 'auto', borderRadius: '15px' }} />
      </Box>

      {/* Heading */}
      <Typography 
        component="h1" 
        sx={{ 
          fontSize: {xs:'20px', md:'22px'}, 
          fontFamily: 'monospace', 
          fontWeight: 'bold', 
          marginBottom: {xs:4, md:6}, 
          textAlign: 'center',
          color: '#00796b' 
        }}
      >
        Choose Your Grubpack Store
      </Typography>

      {/* Cards */}
      <Grid container spacing={3} justifyContent="center">
        {cities.map((city) => (
          <Grid 
            item 
            xs={12} 
            sm={8} 
            md={4} 
            key={city.name}
            sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}
          >
            <Card 
              sx={{ 
                width: '100%', 
                maxWidth: 345, 
                borderRadius: 3, 
                transition: 'transform 0.3s ease, box-shadow 0.3s ease', 
                '&:hover': {
                  transform: 'scale(1.05)', 
                  boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.3)',
                },
              }}
            >
              <CardActionArea onClick={() => handleCityClick(city.key)}>
                <CardMedia 
                  component="img" 
                  height="180" 
                  image={city.image} 
                  alt={city.name} 
                  sx={{
                    filter: 'brightness(0.85)',
                    transition: 'filter 0.3s ease',
                    '&:hover': {
                      filter: 'brightness(1)',
                    },
                  }}
                />
                <CardContent sx={{ backgroundColor: '#f7f7f7' }}>
                  <Typography 
                    variant="h6" 
                    component="div" 
                    sx={{ 
                      fontFamily: 'monospace', 
                      textAlign: 'center', 
                      color: '#00796b', 
                      fontWeight: 'bold', 
                      display: 'flex', 
                      alignItems: 'center', 
                      justifyContent: 'center',
                    }}
                  >
                    <LocationOnIcon sx={{ fontSize: '18px', color: '#ff5722', marginRight: '5px' }} />
                    {city.name}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ChooseStore;
