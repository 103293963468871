import React, { useState } from 'react';
import { Box, List, Divider, Typography, IconButton, Avatar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import productList from '../productList.json';

const Sidebar = ({ onCategoryClick, selectedCategory }) => {
  const [isOpen, setIsOpen] = useState(true);

  const getInitials = (name) => {
    return name
      .split(' ')
      .map((word) => word[0].toUpperCase())
      .join('');
  };

  return (
    <Box
      sx={{
        width: isOpen ? 220 : 60,
        padding: '15px',
        backgroundColor: '#fff',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        transition: 'width 0.4s ease',
        overflow: 'hidden',
      }}
    >
      {/* Sidebar Title */}
      {isOpen && (
        <Typography
          sx={{
            color: '#00796b',
            fontFamily: 'monospace',
            fontSize: '16px',
            fontWeight: 'bold',
            marginBottom: '40px',
            whiteSpace: 'nowrap',
          }}
        >
          Product Categories
        </Typography>
      )}
      {/* <Divider sx={{ backgroundColor: 'rgba(255, 255, 255, 0.2)', marginBottom: '8px', border: '0.5px solid #00796b' }} /> */}

      {/* All Products Option */}
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        {!isOpen && (
          <Avatar
            title="All Products"
            sx={{
              bgcolor: '#00796b',
              marginRight: '10px',
              width: '40px',
              height: '40px',
              fontFamily: 'monospace',
              cursor: 'pointer',
              transition: 'transform 0.3s ease',
              '&:hover': {
                animation: 'jump 0.2s ease-in-out',
              },
              '@keyframes jump': {
                '0%, 100%': {
                  transform: 'translateY(0)',
                },
                '50%': {
                  transform: 'translateY(-10px)',
                },
              },
            }}
            onClick={() => onCategoryClick('All Products')}
          >
            {getInitials('All Products')}
          </Avatar>
        )}
        {isOpen && (
          <Typography
            variant="subtitle2"
            onClick={() => onCategoryClick('All Products')}
            sx={{
              fontWeight: 'bold',
              padding: '6px 12px',
              color: selectedCategory === 'All Products' ? '#fff' : '#00796b',
              cursor: 'pointer',
              fontFamily: 'monospace',
              borderRadius: '10px',
              fontSize: '12px',
              backgroundColor: selectedCategory === 'All Products' ? '#00796b' : 'inherit',
              '&:hover': { color: '#4db6ac', transition: 'color 0.3s ease' },
            }}
          >
            All Products
          </Typography>
        )}
      </Box>

      {/* Product Categories List */}
      <List sx={{ padding: 0 }}>
        {productList.map((category) => (
          <Box key={category.id} sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            {!isOpen && (
              <Avatar
                title={category.name}
                sx={{
                  bgcolor: '#00796b',
                  marginRight: '10px',
                  width: '40px',
                  height: '40px',
                  fontFamily: 'monospace',
                  cursor: 'pointer',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    animation: 'jump 0.5s ease-in-out',
                  },
                  '@keyframes jump': {
                    '0%, 100%': {
                      transform: 'translateY(0)',
                    },
                    '50%': {
                      transform: 'translateY(-10px)',
                    },
                  },
                }}
                onClick={() => onCategoryClick(category)}
              >
                {getInitials(category.name)}
              </Avatar>
            )}
            {isOpen && (
              <Typography
                variant="subtitle2"
                onClick={() => onCategoryClick(category)}
                sx={{
                  fontWeight: 'bold',
                  padding: '6px 12px',
                  color: selectedCategory?.id === category.id ? '#fff' : '#00796b',
                  cursor: 'pointer',
                  fontFamily: 'monospace',
                  borderRadius: '10px',
                  fontSize: '12px',
                  backgroundColor: selectedCategory?.id === category.id ? '#00796b' : 'inherit',
                  '&:hover': { color: '#4db6ac', transition: 'color 0.3s ease' },
                }}
              >
                {category.name}
                
              </Typography>
            )}     
          </Box>
        ))}
      </List>
    </Box>
  );
};

export default Sidebar;